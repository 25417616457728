$grey : #333;
$textColor: #c1c1c1;
$link: #81a2be;

html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: $grey;
    color: $textColor;
    font-family: sans-serif;
}

a {
    text-decoration: none;
    color: $link;
}
a:hover {
    text-decoration: underline;
}
a:visited {
    color: $link;
}
a:active {
    color: $link;
}

#root > header {
    margin-bottom: 0.6rem;
    // padding: 0 5rem;
    display: flex;
    justify-content: space-around;
    align-items: baseline;


    .homelink,
    .authbuttons,
    nav {
        padding: 10px;
        a {
            font-size: 0.9rem;
            // text-decoration: underline;
            font-weight: bold;
        }
    }
    
    .homelink h1 {
        font-size: 0.9rem;

        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        margin: 0;
    }
    
    nav {
        flex-grow: 4;
        display: flex;
        justify-content: baseline;
        a {
            margin: 0 1rem
        }
    }
    
    .authbuttons {
        flex-grow: 1;
        text-align: end;

        a {
            padding-right: 5px;
        }

        button {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

@media (max-width: 600px) {
    #root > header {
        display: none;
    }
}

#root > main {
     width: 100%;
 }

 #configpagestable {
    margin-top: 4rem;
    border: none;
    tr {
        max-height: 20px;
        border: none;
    }
    width: 100%;
    table-layout: fixed;
    td {
        // border: solid 1px $textColor;
        max-height: 20px;
        time {
            font-weight: bold;
        }
    }
    .configpagestableContentCell {
        overflow: hidden;
        font-family: monospace;
    }
 }

 .theConfigpageDisplayTextarea {
    margin: 1rem;
    width: 86%;
    height: 400px;
    font-size: 1.5rem;
    background-color: $grey;
    color: $textColor;
}
.theConfigpageEditorH3,
.theConfigpageDisplayH2 {
    margin-left: 1rem;
}
.theConfigpageEditorH3 {
    margin-top: 3rem;
    border-top: solid 1px $textColor;
    padding-top: 1rem;
}
.theConfigpageDisplayPageLinks,
.theConfigpageDisplay404Buttons,
.theConfigpageDisplayUrlLinks {
    font-size: 1.3rem;
    margin: 1rem;
    li {
        margin-top: 12px;
        margin-bottom: 12px;
        line-height: 36px;
    }
}
.theConfigpageDisplay404Buttons {
    button {
        background: none;
        color: darkred;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
}

.theConfigpageEditorButtonSection {

    margin-left: 1rem;
    margin-top: 1rem;
    button {
        height: 30px;
        color: white;
    }
    #editorDeleteButton {
        background-color: red;
        width: 100px;
    }
    #editorResetButton {
        background-color: orange;
        width: 100px;
    }
    #editorSaveButton {
        background-color: green;
        width: 220px;
    }
}

#createConfigPageSection {
    margin-left: 1rem;
    margin-top: 1rem;
    input {
        width: 50%;
        background-color: $grey;
        color: $textColor;
    }
    button {
        width: 30%;
        color: $textColor;
        background-color: $grey;
    }
}


#fdashboard-refresh {
    margin-right: 6rem;

    margin-bottom: 1rem;
    height: 1rem;
    button {
        float: right;
        width: 33%;
        color: $textColor;
        background-color: $grey;
    }
}
#fdashboard {
    margin-right: 6rem;
}
.fchanThreadOPline {
    margin: 0;
    padding: 4px;
    
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;

    .fcopl-com {
        padding-right: 8px;
        flex-shrink: 2;
    }

    .fcopl-img {
        flex-shrink: 0;
        a {
            color: $textColor;    
        }
    }
    
    .fcopl-filter {
        color: white;
        flex-shrink: 0;
        a {
            color: white;    
        }
    }    
    .fcopl-stats {
        flex-shrink: 0;
        width: 6rem;
        font-size: 0.8rem;
        font-family: monospace;
        text-align: center;
    }
    .fcopl-sub {
        color: white;
        flex-shrink: 0;
        width: 32rem;
        text-align: left;
        a {
            color: white;    
        }
    }
    .fcopl-x-button {
        color: $textColor;
        background-color: $grey;
        margin: 0 2px;
        padding: 0 4px;
    }

    .fcopl-new {
        flex-shrink: 0;
        margin: 0 2px;
        margin-right: 4px;
    }
    .fcopl-new-red {
        color: red;
    }

    .fcopl-sub-input {
        width: 0.8rem;
        background-color: $grey;
        color: $textColor;
    }
    .fcopl-sub-input:focus {
        width: 12rem;
        flex-shrink: 0;
    }

    .fcopl-board {
        color: $textColor;
        flex-shrink: 0;
        //height: 0.9rem;
        font-size: 0.9rem;
        font-family: serif;
    }

    span, small {
        padding: 0 4px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-align: right;
    }
}

@media (max-width: 1000px) {
    #fdashboard {
        margin: 0;
    }
    .fchanThreadOPline {
        .fcopl-sub {
            width: 60%;
        }
        .fcopl-stats { 
            font-size: 0.6rem;
            width: 4rem;
        }
        .fcopl-new { 
            font-size: 0.8rem;
        }
    }
}

@media (max-width: 600px) {
    #fdashboard {
        margin: 0;
    }
    .fchanThreadOPline {
        .fcopl-sub {
            width: 70%;
            font-size: 0.8rem;
        }
        .fcopl-new { 
            font-size: 0.6rem;
        }
        .fcopl-stats { 
            display:none;
        }
        .fcopl-com {
            display:none;
        }
    }
}

#w2w-home,
#w2w-page,
#w2w-w1-pagelist {
    h1 {padding-left: 1rem;}
    section {
        padding: 1rem;
        width: 96%;  
        a {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
        p {
            width: 96%;
            label {
                //NOP
                padding-left: 2rem;
                padding-right: 2rem;
                a {
                    float: right;
                    width: 80%;
                    padding:0;
                }
            }
            input {
                float: right;
                width: 80%;
            }
        }
    }
     
}

#w2w-page {
    button {
        color: $textColor;
        background-color: $grey;
        padding-left: 3rem;
        padding-right: 3rem;
        margin: 0.5rem;
    }
    #thePageData{
        #thePageTitle {
            color:$textColor;
        }
        #theWikiText {
            width: 94%;
            background-color: $grey;
            color: $textColor;
        }
        #thePageMetaDataList {
            font-family: monospace;
        }
        pre {
            word-wrap: break-word;
            text-wrap: wrap;
            width: 94%;
        }
    }
}